<template>
  <div class="content">
    <div v-if="showPage == 'firstPage'">
      <div class="flex_start firstBox">
        <el-image class="logoIcon" :src="logo"></el-image>
        <div>
          <div class="f-gray-4 f-bold f-18 mb10">
            hello,{{ username }},工作顺利哦~
          </div>
          <div>
            <span class="c9 f-14">益鸟精神：客户第一，说到做到！</span>
            <el-button
              size="mini"
              type="primary"
              class="ml30"
              @click="changeLine(2)"
              v-if="line == 1"
              >在线中</el-button
            >
            <el-button
              size="mini"
              class="ml30 bgccc color-white"
              @click="changeLine(1)"
              v-if="line == 2"
              >已离线</el-button
            >
          </div>
        </div>
      </div>
      <div
        class="flex_start"
        v-if="administrators == 1 || administrators == 2 || administrators == 4"
      >
        <div class="leftContent" style="width: 50%">
          <div class="bold b-gray-55 f-18 mar17">数据统计</div>
          <div class="leftBox">
            <div class="child">
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/deal.png"
                ></el-image>
                <div class="title">今日已处理</div>
              </div>
              <div class="number">{{ dataLine.count1 }}</div>
            </div>
            <div class="child">
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/deal.png"
                ></el-image>
                <div class="title">今日待处理</div>
              </div>
              <div class="number">{{ dataLine.count2 }}</div>
            </div>
            <div class="child">
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/deal.png"
                ></el-image>
                <div class="title">今日已指派订单</div>
              </div>
              <div class="number">{{ dataLine.count4 }}</div>
            </div>
            <!-- 此数据已取消 2022.12.29 -->
            <!-- <div class="child" @click="openOrder">
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/deal.png"
                ></el-image>
                <div class="title">近3天接待客户</div>
              </div>
              <div class="number">{{ dataLine.count8 }}</div>
            </div> -->
          </div>
          <div class="mar17 f-18 b-gray-55 f-bold">售后追踪排行榜</div>
          <div class="afterSale">
            <div class="child2 pointer" @click="firstRankDetail">
              <div class="flex_start">
                <el-image
                  class="saleCatch"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/rankIcon.png"
                ></el-image>
                <div class="title">超时未接单排行榜</div>
              </div>
              <div class="number">{{ dataLine.count5 }}</div>
            </div>
            <div class="child2 pointer" @click="secondRankDetail">
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/rankIcon.png"
                ></el-image>
                <div class="title">打卡未报价排行榜</div>
              </div>
              <div class="number">{{ dataLine.count6 }}</div>
            </div>
            <div
              class="child2"
              @click="thirdRankDetail"
              style="cursor: pointer"
            >
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/rankIcon.png"
                ></el-image>
                <div class="title">施工超期排行榜</div>
              </div>
              <div class="number">{{ dataLine.count7 }}</div>
            </div>
          </div>

          <!-- 商机处理统计 -->
          <div class="mar17 f-18 b-gray-55 f-bold">商机处理统计</div>
          <div class="afterSale">
            <div
              class="child2 pointer"
              @click="seeOrderPool('orderPool', 1, 1)"
            >
              <div class="flex_start">
                <el-image
                  class="saleCatch"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/rankIcon.png"
                ></el-image>
                <div class="title">今日待处理</div>
              </div>
              <div class="number">{{ dataLine.count8 }}</div>
            </div>
            <div
              class="child2 pointer"
              @click="seeOrderPool('orderPool', 1, 2)"
            >
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/rankIcon.png"
                ></el-image>
                <div class="title">明日待处理</div>
              </div>
              <div class="number">{{ dataLine.count9 }}</div>
            </div>
            <div
              class="child2"
              style="cursor: pointer"
              @click="seeOrderPool('orderPool', 1, 3)"
            >
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/rankIcon.png"
                ></el-image>
                <div class="title">逾期未处理</div>
              </div>
              <div class="number">{{ dataLine.count10 }}</div>
            </div>
          </div>
          <!-- 有效商机统计（长周期） -->
          <div class="bold b-gray-55 f-18 mar17">有效商机统计（长周期）</div>
          <div class="leftBox">
            <div class="child" @click="seeOrderPool('orderPool', 1, 4)">
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/deal.png"
                ></el-image>
                <div class="title">本月已接待数</div>
              </div>
              <div class="number">{{ dataLine.count11 }}</div>
            </div>
            <div class="child" @click="seeOrderPool('orderPool', 1, 5)">
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/deal.png"
                ></el-image>
                <div class="title">跟进中数</div>
              </div>
              <div class="number">{{ dataLine.count12 }}</div>
            </div>
            <div class="child" @click="seeOrderPool('orderPool', 1, 6)">
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/deal.png"
                ></el-image>
                <div class="title">转派单上门数量</div>
              </div>
              <div class="number">{{ dataLine.count13 }}</div>
            </div>
            <div class="child" @click="seeOrderPool('orderPool', 1, 7)">
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/deal.png"
                ></el-image>
                <div class="title">跟单战败数</div>
              </div>
              <div class="number">{{ dataLine.count14 }}</div>
            </div>
          </div>

          <!-- 一般商机统计 -->
          <div class="bold b-gray-55 f-18 mar17">一般商机统计</div>
          <div class="leftBox">
            <div class="child" @click="seeOrderPool('commonOrderPool', 1, 4)">
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/deal.png"
                ></el-image>
                <div class="title">本月已接待数</div>
              </div>
              <div class="number">{{ dataLine.count15 }}</div>
            </div>
            <div class="child" @click="seeOrderPool('commonOrderPool', 1, 5)">
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/deal.png"
                ></el-image>
                <div class="title">跟进中数</div>
              </div>
              <div class="number">{{ dataLine.count16 }}</div>
            </div>
            <div class="child" @click="seeOrderPool('commonOrderPool', 1, 6)">
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/deal.png"
                ></el-image>
                <div class="title">转派单上门数量</div>
              </div>
              <div class="number">{{ dataLine.count17 }}</div>
            </div>
            <div class="child" @click="seeOrderPool('commonOrderPool', 1, 7)">
              <div class="flex_start">
                <el-image
                  class="showIcon"
                  src="https://imgaes.yiniaoweb.com/Icon/firstPage/deal.png"
                ></el-image>
                <div class="title">跟单战败数</div>
              </div>
              <div class="number">{{ dataLine.count18 }}</div>
            </div>
          </div>

          <div class="mar17 f-18 b-gray-55 f-bold">操作台</div>
          <div class="sendMsg" @click="openSendMsg">
            <div class="flex_start">
              <el-image
                class="showIcon"
                src="https://imgaes.yiniaoweb.com/Icon/firstPage/msg.png"
              ></el-image>
              <div class="title">离职短信通知</div>
            </div>
          </div>
          <div class="mar17 f-18 b-gray-55 f-bold">订单转派记录</div>
          <div class="sendMsg" @click="openTransfer">
            <div class="flex_start">
              <el-image
                class="transferIcon"
                src="https://imgaes.yiniaoweb.com/Icon/firstPage/transfer.png"
              ></el-image>
              <div class="title">订单转派记录查看</div>
            </div>
          </div>
        </div>
        <div class="rightContent" style="margin-left: 10%; width: 35%">
          <div class="mar20 bold b-gray-55 f-18">当前在线伙伴</div>
          <div class="partner">
            <div v-if="usersLine.length > 0">
              <div
                class="flex_start partnerCh"
                v-for="(item, index) in usersLine"
                :key="index"
              >
                <el-image class="partnerIcon" :src="item.logo"></el-image>
                <div>
                  <div class="f-14">{{ item.username }}</div>
                  <div class="cb f-15">在线中</div>
                </div>
              </div>
            </div>
            <div v-else class="c9">暂无其他在线小伙伴~~</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showPage == 'firstRank'" class="showPage">
      <div @click="returnFirstPage" class="returnIcon returnArrow">
        <i class="el-icon-arrow-left"></i>返回列表
      </div>
      <firstRank></firstRank>
    </div>
    <div v-if="showPage == 'secondRank'" class="showPage">
      <div @click="returnFirstPage" class="returnIcon returnArrow">
        <i class="el-icon-arrow-left"></i>返回列表
      </div>
      <secondRank></secondRank>
    </div>
    <div v-if="showPage == 'thirdRank'" class="showPage">
      <div @click="returnFirstPage" class="returnIcon returnArrow">
        <i class="el-icon-arrow-left"></i>返回列表
      </div>
      <thirdRank></thirdRank>
    </div>
    <div v-if="showPage == 'sendMsg'" class="showPage">
      <div @click="returnFirstPage" class="returnIcon returnArrow">
        <i class="el-icon-arrow-left"></i>返回列表
      </div>
      <sendMsg></sendMsg>
    </div>
    <div v-if="showPage == 'transfer'" class="showPage">
      <div @click="returnFirstPage" class="returnIcon returnArrow">
        <i class="el-icon-arrow-left"></i>返回列表
      </div>
      <div class="transferPage">
        <el-select
          :clearable="true"
          v-model="city_id5"
          placeholder="选择城市"
          class="rankStyle inputStyle"
          @change="choseCity5(city_id5)"
          style="height: 30px !important"
        >
          <el-option
            v-for="(item, index) in cities5"
            :key="index"
            :label="item.city"
            :value="item.city_id"
          ></el-option>
        </el-select>
        <el-input
          :clearable="true"
          v-model="orderNoValue"
          placeholder="输入订单号"
          style="height: 30px; width: 300px;margin: 0 20px"
        >
        </el-input>
        <el-button
          type="success"
          @click="getTransferList()"
          >筛选</el-button
        >
      </div>

      <avue-crud
        :data="transferList"
        :option="option5"
        v-model="obj5"
        style
      ></avue-crud>
      <div class="flex_start mt20 flex_items" style>
        <span class="demonstration mr20">
          总共
          <span style="color: red">{{ count5 }}</span
          >条数据,当前 <span style="color: red">{{ transferList.length }}</span
          >条
        </span>
        <el-pagination
          @current-change="handleCurrentChange5"
          :current-page.sync="currentPage5"
          :page-size="20"
          :total="count5"
          layout="prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <div v-if="showPage == 'orderfinding'" class="showPage">
      <div @click="returnFirstPage" class="returnIcon returnArrow">
        <i class="el-icon-arrow-left"></i>返回列表
      </div>
      <Orderfinding
        v-if="showPage == 'orderfinding'"
        :near="number"
      ></Orderfinding>
    </div>

    <!-- 超时未接单任务完成模态框 -->
    <el-dialog
      title="选择新店长"
      :visible.sync="shopperDialog"
      width="45%"
      z-index="100"
      center
      top="15%"
    >
      <div style="display: flex; padding: 0 15% 5% 15%">
        <div
          style="margin-right: 5%; width: 130px; font-size: 16px; color: #999"
        >
          选择新店长
        </div>
        <div>
          <el-select
            v-model="shopper2"
            placeholder="请选择转派店长"
            style="width: 90%"
            filterable
          >
            <el-option
              v-for="(item, index) in Shopkeepers2"
              :key="index"
              :label="item.usernamestorename"
              :value="item.user_id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="shopperDialog = false"
          >取 消</el-button
        >
        <el-button type="primary" size="medium" plain @click="sendToClient"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <OrderPool
      v-if="showPage == 'orderPool'"
      :order_type="order_type"
      :order_num="order_num"
    ></OrderPool>
    <commonOrderPool
      v-if="showPage == 'commonOrderPool'"
      :order_type="order_type"
      :order_num="order_num"
    ></commonOrderPool>
  </div>
</template>
<script>
export default {
  data() {
    return {
      order_type: "",
      active: "",
      dataLine: {},
      administrators: "",
      showPage: "firstPage",
      username: "",
      city: "",
      store: "",
      shopper: "",
      shopper2: "",
      stores: [],
      shoppers: [],
      shoppers2: [],
      count1: 0,
      count2: 0,
      count3: 0,
      count4: 0,
      count5: 0,
      count: 0,
      time1: 0,
      time2: 0,
      time3: 0,
      obj1: {},
      obj2: {},
      obj3: {},
      obj4: {},
      obj5: {},
      tableData1: [],
      tableData2: [],
      tableData3: [],
      managerList: [],
      transferList: [],
      option1: {
        border: true,
        size: "medium",
        menu: false,
        page: false,
        align: "left",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        columnBtn: false, //列显隐按钮
        refreshBtn: false, //刷新按钮
        //   delBtn:false,
        //   editBtn:false,
        column: [
          {
            label: "店长",
            prop: "username",
            width: "150px",
          },
          {
            label: "店铺",
            prop: "store_name",
            width: "100px",
          },
          {
            label: "订单号",
            prop: "order_id",
          },
          {
            label: "工地地址",
            prop: "addres",
          },
          {
            label: "客户姓名",
            prop: "contacts",
            width: "100px",
          },
          {
            label: "客户电话",
            prop: "telephone",
            width: "130px",
          },
          {
            label: "下单时间",
            prop: "created_time",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
            sortable: true,
          },
          {
            label: "派单时间",
            prop: "writetime",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
          },
          {
            label: "超时时间",
            prop: "overtime",
            sortable: true,
          },
        ],
      },
      value: "",
      option2: {
        border: true,
        size: "medium",
        menu: false,
        page: false,
        align: "left",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        columnBtn: false, //列显隐按钮
        refreshBtn: false, //刷新按钮
        //   delBtn:false,
        //   editBtn:false,
        column: [
          {
            label: "店长",
            prop: "username",
            width: "150px",
          },
          {
            label: "店铺",
            prop: "store_name",
            width: "100px",
          },
          {
            label: "订单号",
            prop: "order_id",
          },
          {
            label: "工地地址",
            prop: "addres",
          },
          {
            label: "客户姓名",
            prop: "contacts",
            width: "100px",
          },
          {
            label: "客户电话",
            prop: "telephone",
            width: "130px",
          },
          {
            label: "下单时间",
            prop: "created_time",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
            sortable: true,
          },
          {
            label: "打卡时间",
            prop: "clock_in_time",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
          },
          {
            label: "超时天数",
            prop: "overtime",
            slot: true,
            sortable: true,
          },
        ],
      },
      option3: {
        border: true,
        size: "medium",
        menu: false,
        page: false,
        align: "left",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        columnBtn: false, //列显隐按钮
        refreshBtn: false, //刷新按钮
        //   delBtn:false,
        //   editBtn:false,
        column: [
          {
            label: "店长",
            prop: "username",
            width: "150px",
          },
          {
            label: "店铺",
            prop: "store_name",
            width: "100px",
          },
          {
            label: "订单号",
            prop: "order_id",
          },
          {
            label: "工地地址",
            prop: "addres",
          },
          {
            label: "客户姓名",
            prop: "contacts",
            width: "100px",
          },
          {
            label: "客户电话",
            prop: "telephone",
            width: "130px",
          },
          {
            label: "下单时间",
            prop: "created_time",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
            sortable: true,
          },
          {
            label: "开工时间",
            prop: "start_time",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
          },
          {
            label: "超时时间",
            prop: "overtime",
            slot: true,
            sortable: true,
          },
        ],
      },
      option4: {
        border: true,
        size: "medium",
        // menu: false,
        page: false,
        width: "90%",
        align: "left",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        columnBtn: false, //列显隐按钮
        refreshBtn: false, //刷新按钮
        column: [
          {
            label: "店长",
            prop: "username",
          },

          {
            label: "店长id",
            prop: "user_id",
            hide: true,
          },
          {
            label: "店铺",
            prop: "store_name",
          },
          {
            label: "城市",
            prop: "city",
          },
          {
            label: "订单总数",
            prop: "count",
          },
        ],
      },
      option5: {
        border: true,
        size: "medium",
        menu: false,
        page: false,
        width: "90%",
        align: "left",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        columnBtn: false, //列显隐按钮
        refreshBtn: false, //刷新按钮
        column: [
          {
            label: "订单id",
            prop: "order_id",
          },
          {
            label: "订单号",
            prop: "order_no",
          },
          {
            label: "创建时间",
            prop: "time",
          },
          {
            label: "地址",
            prop: "addres",
          },
          {
            label: "转派人",
            prop: "username",
          },
          {
            label: "操作人",
            prop: "operation",
          },
        ],
      },
      stores: [],
      cities: [],
      currentPage1: 1,
      currentPage2: 1,
      currentPage3: 1,
      currentPage4: 1,
      currentPage5: 1,
      line: 0,
      icon_url: "",
      usersLine: [],
      logo: "",
      shopperDialog: false,
      create_time1: "",
      create_time2: "",
      create_time3: "",
      city_id5: 241,
      orderNoValue: "",

      number: 1,
    };
  },
  mounted() {
    this.administrators = sessionStorage.getItem("administrators");
    let username = sessionStorage.getItem("username");
    this.username = username;
    this.getCity();
    this.getFirst();
    this.getusersLine();
    if (sessionStorage.getItem("line")) {
      this.line = sessionStorage.getItem("line");
    } else {
      this.line = 1;
    }
  },
  methods: {
    hide() {
      this.showPage = "firstPage";
    },
    openOrder() {
      this.showPage = "orderfinding";
    },
    seeOrderPool(name, type, num) {
      this.showPage = name;
      this.order_type = type;
      this.order_num = num;
      // this.$parent.firstChild();
    },
    getusersLine() {
      //获取在线人数
      this.util.get(this.HOST + "/User/allOnline", {}).then((res) => {
        if (res.code == 200) {
          this.usersLine = res.data;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    changeLine(num) {
      //展示在线或者离线
      this.$loading(this.util.opload);
      this.util
        .get(this.HOST + "/User/online", {
          line: num,
        })
        .then((res) => {
          if (res.code == 200) {
            sessionStorage.setItem("line", res.data.line);
            this.line = sessionStorage.getItem("line");
            this.logo = sessionStorage.getItem("logo");
            this.getusersLine();
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getFirst() {
      //获取首页的总体数据展示
      this.$loading(this.util.opload);
      this.util.get(this.HOST + "/Cc/DataLine", {}).then((res) => {
        if (res.code == 200) {
          this.dataLine = res.data;
          this.logo = sessionStorage.getItem("logo");
          this.$loading().close();
        } else {
          this.$loading().close();
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    getCity() {
      //获取城市
      this.util.get(this.HOST + "/Common/cheng", {}).then((res) => {
        if (res.code == 200) {
          this.cities = res.data;
          this.cities5 = res.data;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    getShopper(city) {
      //获取店长
      this.util
        .get(this.HOST + "/Common/user", {
          city_id: city,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getShopper2(city) {
      //获取店长
      this.util
        .get(this.HOST + "/Order/getUserList", {
          city_id: city,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers2 = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getStores(city) {
      //获取选取城市的店铺
      this.util
        .get(this.HOST + "/Common/store", {
          city_id: city,
        })
        .then((res) => {
          //console.log(res, "stores++++");
          if (res.code == 200) {
            this.stores = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    rankTime1(time) {
      //根据超时未接单排序
      this.time1 = time;
      this.getOverList();
    },
    rankTime2(time) {
      //根据打卡未报价排序
      this.time2 = time;
      // this.time = time
      this.getClockList();
    },
    rankTime3(time) {
      //根据施工超期排序
      this.time3 = time;
      // this.time = time
      this.getBuildList();
    },
    choseCity1(city) {
      //选择城市-超时未接单
      this.shopper = "";
      this.store = "";
      this.getShopper(city);
      this.getStores(city);
      this.getOverList();
    },
    choseCity2(city) {
      //选择城市-打卡未报价
      this.shopper = "";
      this.store = "";
      this.getShopper(city);
      this.getStores(city);
      this.getClockList();
    },
    choseCity3(city) {
      //选择城市-施工超期
      this.shopper = "";
      this.store = "";
      this.getShopper(city);
      this.getStores(city);
      this.getBuildList();
    },
    choseCity5(city) {
      //选择城市-发送短信
      this.currentPage5 = 1;
      this.getTransferList(city);
    },
    choseCity4(city) {
      //选择城市-发送短信
      this.shopper = "";
      this.getShopper2(city);
    },
    choseShopkeeper1(shopper) {
      //选择店长之后的操作-超时未接单
      this.getOverList();
    },
    choseShopkeeper2(value) {
      //选择店长之后的操作-打卡未报价
      this.getClockList();
    },
    choseShopkeeper3(value) {
      //选择店长之后的操作-施工超期
      this.getBuildList();
    },
    choseShopkeeper4(value) {
      //选择店长之后的操作-施工超期
      this.getManagers();
    },
    choseStore1(value) {
      //选择店铺之后-超时未接单
      this.getOverList();
    },
    choseStore2(value) {
      //选择店铺之后-打卡未报价
      this.getClockList();
    },
    choseStore3(value) {
      //选择店铺之后-施工超期
      this.getBuildList();
    },
    firstRankDetail() {
      //进入打卡未报价排行榜
      this.showPage = "firstRank";
      this.getOverList();
    },
    secondRankDetail() {
      //进入打卡未报价排行榜
      this.showPage = "secondRank";
      this.getClockList();
    },
    thirdRankDetail() {
      //进入施工超期排行榜
      this.showPage = "thirdRank";
      this.getBuildList();
    },

    openSendMsg() {
      //进入发送短信控制台
      this.showPage = "sendMsg";
      // this.getManagers()
    },
    openTransfer() {
      //进入订单转派列表
      this.getTransferList();
      this.showPage = "transfer";

      // this.getManagers()
    },
    returnFirstPage() {
      //返回首页
      this.showPage = "firstPage";
      this.time1 = 0;
      this.time2 = 0;
      this.time3 = 0;
      this.city = "";
      this.shopper = "";
      this.store = "";
      this.currentPage1 = 1;
      this.currentPage2 = 1;
      this.currentPage3 = 1;
      this.shopper2 = "";
      this.create_time1 = "";
      this.create_time2 = "";
      this.create_time3 = "";
      this.city_id5 = 241;
      //console.log(this.create_time1, "this.create_time1+");
    },
    getOverList() {
      //超时未接单排行榜列表
      if (this.create_time1 == null || this.create_time1 == "") {
        this.create_time1 = "";
      }
      let time_start = this.util.datatime(this.create_time1[0]);
      let time_end = this.util.datatime(this.create_time1[1]);
      if (time_start == "NaN-NaN-NaN NaN:NaN:NaN") {
        time_start = "";
        time_end = "";
      }
      this.$loading(this.util.opload);
      this.util
        .get(this.HOST + "/Order/overtime", {
          page: 1,
          limit: 20,
          user_id: this.shopper,
          store_id: this.store,
          city_id: this.city,
          desc: this.time1,
          time_start: time_start,
          time_end: time_end,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count1 = res.data.count;
            for (let i = 0; i < res.data.list.length; i++) {
              res.data.list[i].clock_in_time = this.util.timestampToTime(
                res.data.list[i].clock_in_time
              );
            }
            this.tableData1 = res.data.list;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getClockList() {
      //打卡未报价排行榜列表
      this.$loading(this.util.opload);
      if (this.create_time2 == null || this.create_time2 == "") {
        this.create_time2 = "";
      }
      let time_start = this.util.datatime(this.create_time2[0]);
      let time_end = this.util.datatime(this.create_time2[1]);
      if (time_start == "NaN-NaN-NaN NaN:NaN:NaN") {
        time_start = "";
        time_end = "";
      }
      this.util
        .get(this.HOST + "/Order/Clock", {
          page: 1,
          limit: 20,
          user_id: this.shopper,
          store_id: this.store,
          city_id: this.city,
          desc: this.time2,
          time_start: time_start,
          time_end: time_end,
        })
        .then((res) => {
          if (res.code == 200) {
            //console.log(res.data.count, "res.data.count!!!!!+++++");
            this.count2 = res.data.count;
            for (let i = 0; i < res.data.list.length; i++) {
              res.data.list[i].clock_in_time = this.util.timestampToTime(
                res.data.list[i].clock_in_time
              );
            }
            this.tableData2 = res.data.list;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getBuildList() {
      //施工超期排行榜
      this.$loading(this.util.opload);
      if (this.create_time3 == null || this.create_time3 == "") {
        this.create_time3 = "";
      }
      let time_start = this.util.datatime(this.create_time3[0]);
      let time_end = this.util.datatime(this.create_time3[1]);
      if (time_start == "NaN-NaN-NaN NaN:NaN:NaN") {
        time_start = "";
        time_end = "";
      }
      this.util
        .get(this.HOST + "/Order/Construction", {
          page: 1,
          limit: 20,
          user_id: this.shopper,
          store_id: this.store,
          city_id: this.city,
          desc: this.time3,
          time_start: time_start,
          time_end: time_end,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count3 = res.data.count;
            for (let i = 0; i < res.data.list.length; i++) {
              res.data.list[i].clock_in_time = this.util.timestampToTime(
                res.data.list[i].clock_in_time
              );
            }
            this.tableData3 = res.data.list;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getManagers() {
      //获取店长列表
      this.managerList = [];
      this.util
        .get(this.HOST + "/Order/orderQuitList", {
          city_id: this.city,
          user_id: this.shopper,
        })
        .then((res) => {
          if (res.code == 200) {
            // let arr = []
            // let a  = arr.push(res.data)
            // this.managerList = a[0]
            // this.managerList = res.data
            this.managerList.push(res.data);

            //console.log(this.managerList, "this.managerList++");
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getTransferList() {
      //获取店长列表
      this.transferList = [];
      this.$loading(this.util.opload);
      this.util
        .get(this.HOST + "/Common/TransferRecord", {
          city_id: this.city_id5,
          order_no: this.orderNoValue,
          page: 1,
          limit: 20,
        })
        .then((res) => {
          if (res.code == 200) {
            //console.log(res.data.data, "getTransferList++++++++++");
            this.count5 = res.data.count;
            // let arr = []
            // let a  = arr.push(res.data)
            // this.managerList = a[0]
            // this.managerList = res.data
            this.transferList = res.data.data;
            this.$loading().close();
            // //console.log(this.transferList, "this.getTransferList++!!!!!!!");
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    sendToClient() {
      //发送短信
      this.$loading(this.util.opload);
      this.util
        .get(this.HOST + "/Order/orderQuit", {
          city_id: this.city,
          user_id: this.shopper,
          shopwoners: this.shopper2,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: "短信发送成功！",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            this.shopper2 = "";
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    handleCurrentChange1(val) {
      if (this.create_time1 == null || this.create_time1 == "") {
        this.create_time1 = "";
      }
      let time_start = this.util.datatime(this.create_time1[0]);
      let time_end = this.util.datatime(this.create_time1[1]);
      if (time_start == "NaN-NaN-NaN NaN:NaN:NaN") {
        time_start = "";
        time_end = "";
      }
      this.$loading(this.util.opload);
      this.tableData1 = [];
      this.util
        .get(this.HOST + "/Order/overtime", {
          limit: 20,
          user_id: "",
          store_id: "",
          city_id: "",
          page: this.currentPage1,
          desc: this.time1,
          time_start: time_start,
          time_end: time_end,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count1 = res.data.count;
            this.tableData1 = res.data.list;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    handleCurrentChange2(val) {
      if (this.create_time2 == null || this.create_time2 == "") {
        this.create_time2 = "";
      }
      let time_start = this.util.datatime(this.create_time2[0]);
      let time_end = this.util.datatime(this.create_time2[1]);
      if (time_start == "NaN-NaN-NaN NaN:NaN:NaN") {
        time_start = "";
        time_end = "";
      }
      this.$loading(this.util.opload);
      this.tableData2 = [];
      this.util
        .get(this.HOST + "/Order/Clock", {
          limit: 20,
          user_id: "",
          store_id: "",
          city_id: "",
          page: this.currentPage2,
          desc: this.time2,
          time_start: time_start,
          time_end: time_end,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count2 = res.data.count;
            for (let i = 0; i < res.data.list.length; i++) {
              res.data.list[i].clock_in_time = this.util.timestampToTime(
                res.data.list[i].clock_in_time
              );
              this.tableData2 = res.data.list;
            }
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    handleCurrentChange3(val) {
      if (this.create_time3 == null || this.create_time3 == "") {
        this.create_time3 = "";
      }
      let time_start = this.util.datatime(this.create_time3[0]);
      let time_end = this.util.datatime(this.create_time3[1]);
      if (time_start == "NaN-NaN-NaN NaN:NaN:NaN") {
        time_start = "";
        time_end = "";
      }
      this.$loading(this.util.opload);
      this.tableData3 = [];
      this.util
        .get(this.HOST + "/Order/Construction", {
          limit: 20,
          user_id: "",
          store_id: "",
          city_id: "",
          page: this.currentPage3,
          desc: this.time3,
          time_start: time_start,
          time_end: time_end,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count3 = res.data.count;
            for (let i = 0; i < res.data.list.length; i++) {
              res.data.list[i].clock_in_time = this.util.timestampToTime(
                res.data.list[i].clock_in_time
              );
              this.tableData3 = res.data.list;
            }
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    handleCurrentChange5(val) {
      this.$loading(this.util.opload);
      this.transferList = [];
      this.util
        .get(this.HOST + "/Common/TransferRecord", {
          limit: 20,
          city_id: this.city_id5,
          page: this.currentPage5,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count5 = res.data.count;
            this.transferList = res.data.data;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    startSendMsg(row) {
      this.shopperDialog = true;
    },
    mouseOver: function () {
      this.active = "display:inline;";
    },
    mouseLeave: function () {
      this.active = "display:none;";
    },
  },
  computed: {
    Shopkeepers() {
      var _this = this;
      var Shopkeepers = [];
      this.shoppers.map((item) => {
        if (item.username.search(_this.shop) != -1) {
          Shopkeepers.push(item);
        }
      });
      return Shopkeepers;
    },
    Shopkeepers2() {
      var _this = this;
      var Shopkeepers2 = [];
      this.shoppers2.map((item) => {
        if (item.username.search(_this.shop) != -1) {
          Shopkeepers2.push(item);
        }
      });
      return Shopkeepers2;
    },
  },
  components: {
    Orderfinding: () => import("@/components/orderfinding.vue"),
    firstRank: () => import("@/views/firstPageViews/firstRank.vue"),
    secondRank: () => import("@/views/firstPageViews/secondRank.vue"),
    thirdRank: () => import("@/views/firstPageViews/thirdRank.vue"),
    sendMsg: () => import("@/views/firstPageViews/sendMsg.vue"),
    OrderPool: () => import("@/views/orderPool.vue"), //有效商机池
    commonOrderPool: () => import("@/views/commonOrderPool.vue"), //一般商机池
  },
};
</script>
<style scoped>
.content {
  margin: 0 auto;
  background: white;
  padding: 0 0 0 30px;
  border-radius: 8px;
  height: 100%;
}
.child {
  width: 24%;
  margin-bottom: 10%;
}
.child2 {
  width: 32%;
  margin-bottom: 10%;
}
.number {
  font-size: 26px;
  /* text-align: center; */
  color: #999;
  margin: 5% 0 0 25%;
}
.title {
  font-size: 16px;
  margin-top: 20px;
  text-align: left;
  color: #666;
  width: 145px;
}
.sort {
  margin-bottom: -20px;
}
.rankStyle {
  margin-left: 20px;
}
.selectedTime {
  color: #409eff;
}
.icon {
  background: url(".././assets/icons/icon0.png") no-repeat;
  background-size: 100%;
  height: 50px;
  width: 50px;
}
.inputStyle {
  height: 32px !important;
  width: 180px !important;
}
.returnIcon:hover {
  color: cornflowerblue;
}
div /deep/ .avue-crud__menu {
  min-height: 0px !important;
}
.el-icon-warning:hover span {
  display: block;
}
/* .datePicker {
  width: 256px;
  height: 32px !important;
  line-height: 32px !important;
} */
.littleBox input {
  height: 32px !important;
  line-height: 32px !important;
}
</style>
